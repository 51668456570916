.userindex_top {
  margin-bottom: 32px;
}
.userindex_pagination {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.userindex_noUsers {
  margin-top: 50px;
  color: #0C5371;
  text-align: center;
  font-weight: normal;
}

.userindex_noUsers_btn {
	margin: 32px auto;
}