.toggle-icon {
  background-repeat: no-repeat;
  height: 16px;
  width: 16px;
  -webkit-mask-image: url(/assets/imgs/icons/expand2.svg);
  background: #1D9ABB;
  -webkit-mask-size: contain;

  &--toggled {
    -webkit-mask-image: url(/assets/imgs/icons/expandicon.svg);
  }
}
