.pagination {
    display: flex;
    align-items: center;
}

.pagination_icon {
    height: 32px;
    width: 32px;
    list-style: none;
    &:hover {
        cursor: pointer;
    }
}
.pagination_icon--left {
    margin-right: 7px;
    list-style: none;
}
.pagination_icon--right {
    margin-left: 7px;
    list-style: none;
    display: flex;
    justify-content: flex-end;
}
.pagination_dots {
    list-style: none;
}
.pagination_link {
    display: block;
    outline: none;
}
.pagination_number {
    color: #0C5271;
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 7px;
    list-style: none;
    &:hover {
        cursor: pointer;
    }
    a {
        outline: none;
    }
}

.pagination_number:last-of-type {
    margin-right: 0;
}
.pagination_number:first-of-type {
    margin-left: 0;
}

.pagination--active {
    color: #1D9ABB;
}
