.divider {
  box-sizing: border-box;
  height: 1px;
  background: #bdbec1;
  margin-bottom: 15px;
}

.divider--larger {
  box-sizing: border-box;
  height: 2px;
  background: #bdbec1;
  margin-bottom: 15px;
}

.usercreate_card_divider {
  margin-top: 24px;
  margin-bottom: 58px;
}

.ordershipment_card_divider {
  width: 100%;
  box-sizing: border-box;
  height: 1px;
  background: #BDC6CF
}
