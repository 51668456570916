.auth-content {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  background: url(/assets/imgs/auth-illustration-dark.png);
  background-position: bottom;
  background-size: auto 140px;
  background-repeat: repeat-x;
  background-position-y: 93%;
  display: flex;
}

.auth-page-body {
  background-color: transparent;
  display: flex;
  flex-direction: column;
}

.auth-page {
  background-color: transparent;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 35px;
}

.auth-page_top {
  margin-top: 35px;
}
.auth-page_top_img {
  height: 49.2px;
  width: 48px;
  margin-left: 35px;
}

.auth-page_mid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 596px;
}
.auth-page_chillLogo {
  height: 34px;
  width: 236px;
  margin-bottom: 20px;
}
.auth-page_chillLogo--forgot {
  margin-bottom: 30px;
}

.auth-page_input {
}
.auth-page_input--forgot {
  margin-bottom: 5px;
}

.auth-page-btn-container {
  z-index: 1;
  margin: 35px 0;
}

.auth-page_btn {
  width: 358px;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
}

.auth-forgot_btn {
  width: 358px;
  padding: 2px 0;
  margin: 0px;
}

.auth-page_btn-icon-label-wrapper {
  display: flex;
  flex-direction:  row;
  align-items: center;
  justify-content:  center;
}
.auth-page_btn-icon {
  height: 14px;
  width: 14px;
  margin-right: 4px;
}
.auth-page_error {
  left: 0;
  right: 0;
  text-align: center;
  padding: 2em;
  max-width: 540px;
}
.auth-page_forgot {
  z-index: 1;
}

.auth-page-background {
  position: fixed;
  bottom: 150px;
  width: 100%;
}
.auth-page-background--company {
  margin-top: -54px;
}
.auth-page-background_img {
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.footer--auth {
  bottom: 0px;
  position: absolute;
  width: 100%;
  height: 80px;
  text-align: center;
  background-color: transparent;
}
.footer--auth-company {
  margin-top: 230px;
  position: absolute;
  width: 100%;
  text-align: center;
  background: white;
}

.auth-page_info_heading {
  color: #0c5371;
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  text-align: center;
  font-weight: normal;
  margin-bottom: 16px;
}
.auth-page_info_text {
  color: #747d86;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.auth-button-progress-container {
  position: absolute;
  left: 8px;
  top: 8px;
}
