.footer {
  width: 1116px;
  margin: 0 auto;
  flex-shrink: 0;
  background-color: #f4f8f9;
  margin-top: 84px;
}

.footer p {
  padding: 40px 0 16px 0;
}

.footer--user {
  margin-top: 80px;
}
