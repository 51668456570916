.art-assignments-modal{

  height: inherit;
  > div {
    height: inherit;
    box-shadow: none !important;
    > div {
      height: 100%;
      max-height: none !important;
    }
  }

  .art-assignments-modal__assignments{
    height: inherit;
    display: flex;
    overflow-y: auto;

    .assignments__images{
      width: 25%;

      .image__container{
        height: 186px;
        margin: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        box-shadow: inset 0 0 0 1px #FFF;

        &.active{
          box-shadow: inset 0 0 0 2px #1D9ABB;
        }

        img {
          max-width: 90%;
          max-height: 182px;
        }

        .uploader-container{
          width: 100%;
          background: #0C5371;
          position: absolute;
          bottom: 0px;
          height: 30px;

          &:hover{
            background: #10759F;
          }

          &:active{
            background: #073143;
          }

          span {
            color: white;
          }
        }
      }
    }

    .assignments__art{
      width: 75%;
      display: flex;
      justify-content: flex-start;
      margin-top: 15px;
      align-items: center;
      flex-direction: column;

      >span {
        color: #929DA8;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }

      > img {
        width: 85%;
        border: 1px solid #BDBEC1;
      }
    }
  }
}
