.order-note-container {
  margin-top: 16px;
  width: 100%;

  .note-title{
    color: #4A5158;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    margin-bottom: 11px;
  }

  .order-note{
    width: 50%;

    &:hover{
      border: 1px solid #34b8dd;
    }

    &--editing{
      border: 1px solid #0C5271 !important;
    }

    &--disabled{
      background-color: rgba(255,255,255,1);
      &:hover{
        border: 1px solid rgba(189, 198, 207, 0.07);
      }
    }

    &--first{
      margin-right: 16px;
    }

    height: 120px;
    border: 1px solid #0C5271;
    border-radius: 4px;
    border: 1px solid rgba(189, 198, 207, 0.07);
    background-color: rgba(255,255,255,0.7);

    > .details {
      height: 100%;
      margin: 16px;

      .section-header{
        margin-bottom: 8px;
      }

      p {
        height: 65px;
        overflow: auto;
	font-size: 12px;
        color: #747D86;
        line-height: 16px;
      }
    }

    > .editable {
      height: 100%;
      margin: 16px;
      .section-header{
        margin-bottom: 8px;
      }
    }
  }
}
