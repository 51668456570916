.dashboard_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.dashboard_top_icon {
  height: 32px;
  width: 32px;
  margin-right: 8px;
}
.dashboard_top_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.dashboard_top h2 {
  display: inline;
}

.dashboard_card_message {
  color: #747d86;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

.dashboard_noResults {
  margin-top: 50px;
  color: #747d86;
  text-align: center;
  font-weight: normal;
}
