.product-title{
  font-size: 24px;
  line-height: 32px;
  color: #0c5371;
  font-weight: normal;
}

.product-details--info{
  width: 55%;
  h4 {
    font-weight: normal;
    font-size: 16px;
    color: #4A5158;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .product-detail-attribute {
    width: 70%;
    color: #747D86;
    font-size: 14px;
    span {
      margin-right: 16px;
    }
  }

}
.detail-table_col{
  width: 100%;
}

.product-detail--image {
  height: 369px;
  min-width: 246px;
  img {
    object-fit: contain;
    height: 369px;
  }

}

.product-detail--art-image {
  margin-top: 24px;
  width: 75%;
  min-width: 188px;
  overflow-x: auto;
  white-space: nowrap;
  img {
    margin-right: 16px;
    box-shadow: inset 0 0 0 1px #BDC6CF;
    padding: 7px;

    &.active{
      box-shadow: inset 0 0 0 2px #1D9ABB;
    }
  }
}

