.ie-wrapper{
  width: 100%;
  height: 100%;
}
.ie-container{
  margin-top: 10%;
  margin-left: 25%;
  width: 50% !important;
  border: 1px solid #0C5371;

  .ie-description{
    padding: 10px;
  }

  .ie-header{
    background: #0C5371;
    img {
      padding: 10px;
      width: 80px;
    }
  }
  .browser{
    div{
      display: inline-block;
      padding: 10px 20px;
      img {
        height: 80px;
        width: 80px;
        border: 0px;
      }
    }
  }
}
