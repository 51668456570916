.w-auto {
  width: auto;
  padding: 0 16px;
}

.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.flex-center {
  align-items: center;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-baseline {
  align-items: baseline;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around{
  justify-content: space-around
}
.justify-content-center{
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}
.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-one {
  flex: 1;
}

.badge {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: -6px;
  right: -6px;
  font-weight: 500;
  font-size: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #22B6DD;
  color: white;
}

.pointer:hover {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.hidden-safe {
 font-size: 20px;
 margin: 0;
 opacity: 0;
 padding: 0;
 position: absolute;
 right: 0;
 top: 0;
 width: 10px;
 input {
   pointer-events: none;
   user-select: none;
 }
}
.text-uppercase {
  text-transform: uppercase;
}

.icon-20 {
  height: 20px;
  width: 20px;
}
.icon-24 {
  height: 24px;
  width: 24px;
}
.icon-14 {
  height: 14px;
  width: 14px;
}

.no-wrap {
  white-space: nowrap;
}

.flex-direction-reverse{
  flex-direction: row-reverse;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-85 {
  width: 85%;
}

.w-50 {
  width: 50%;
}

.w-45 {
  width: 45%;
}

.bg-white {
  background-color: white;
}

.not-implemented, .disabled-button {
  pointer-events: none;
  cursor: default;
  user-select: none;
  -webkit-touch-callout: none;
  & > * {
    opacity: 0.75 !important;
  }
}

.disabled-button {
  &, & > * {
    opacity: 0.5 !important;
    cursor: not-allowed;
  }
}

.not-allowed {
  cursor: not-allowed!important;
}

.center-position-absolute {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.right-position-absolute {
  position: absolute !important;
  right: 0;
  top: 50%;
}

.z-index-3{
  z-index: 3;
}

.padding-0 {
  padding: 0px !important;
}

.padding-8 {
  padding: 8px;
}

.padding-20 {
  padding: 20px;
}

.padding-32 {
  padding: 32px;
}

.padding-40 {
  padding: 40px;
}

.padding-right-10{
  padding-right: 10px;
}

.padding-right-16{
  padding-right: 16px;
}

.padding-left-8 {
  padding-left: 8px;
}

.padding-left-24 {
  padding-left: 24px;
}

.padding-top-4 {
  padding-top: 4px;
}

.padding-top-8 {
  padding-top: 8px;
}

.padding-top-24 {
  padding-top: 24px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-right-4{
  margin-right: 4px;
}

.margin-right-5{
  margin-right: 5px;
}

.margin-right-20{
  margin-right: 20px;
}

.margin-left-8{
  margin-left: 8px;
}

.margin-left-10{
  margin-left: 10px;
}

.margin-left-20{
  margin-left: 20px;
}

.margin-left-40{
  margin-left: 40px;
}

.margin-left-80{
  margin-left: 80px;
}

.margin-bottom-9 {
  margin-bottom: 9px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.margin-bottom-24 {
  margin-bottom: 24px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 190px;
}

.error-container {
  background-color: rgba(244, 67, 54, 0.6);
  margin: 0.8em 0;
}

.datepicker-focus > input::selection{
  background: rgba(187, 220, 255, 0.5);
}

.error-message {
  padding: 5px;
  color: $grey-darker;
}

.text-center {
  text-align: center;
}

.margin-auto {
  margin: auto;
}

.position-relative {
  position: relative;
}

@keyframes placeHolderShimmer {
  0% { background-position: -460px 0; }
  100% { background-position: 460px 0; }
}

.loading{
  position: relative;
  min-height: 15px;
  margin-bottom: 5px;

  &:before {
    content: '';
    background: linear-gradient(to right,  #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 1em;
    position: absolute;
    height: 100%;
    width: 100%;
    animation: 1.5s placeHolderShimmer linear infinite;
    z-index: 2;
  }
}


@media print {
  .non-printable {
    display: none !important;
  }

  .only-print-version {
    display: inherit !important;
  }
}

.only-print-version {
  display: none;
}

.chill-icon {
  color: #1D9ABB !important;

  &:focus {
    color: #22B6DD !important;
  }

  &:hover{
    color: #22B6DD !important;
  }
}

.order-item-dialog > div > div > div {
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.3) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: none !important;
}
