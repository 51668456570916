.page-title {
  color: #0c5371;
  font-size: 30px;
  font-weight: 300;
  line-height: 32px;
  margin: 0;
}

.large-title {
  color: #0c5371;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  margin: 0;
}

.normal-title {
  color: #0c5371;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
  margin: 0;
}

h1,
.page-heading {
  color: #0c5371;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  margin: 0;
}

.small-header,
.link-header {
  color: #0c5271;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}

h3 {
  color: #0C5371;
  font-family: Roboto;
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
}

.subtext {
  font-size: 14px;
  color: #747d86;
  line-height: 20px;
  font-weight: normal;
}
.text-cancel {
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #0c5271;
  font-size: 14px;
}

.section-header {
  color: #929da8;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-transform: uppercase;
}
.section-header--large {
  font-size: 16px;
}

.table-subtext {
  font-size: 12px;
  color: #747d86;
  line-height: 15px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.small-text {
  color: #747d86;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
}

.link-text {
  color: #0c5271;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  text-decoration: none;
  &:hover {
    color: #0C5371;
    text-decoration: underline;
  }
}

.error-text {
  font-size: 14px;
  color: #D0021B;
}

.form-label {
  color: $greyDark2;
  font-size: 16px;
  line-height: 24px;
  display: block;
}

.font-weight-bold{
  font-weight: bold !important;
}

.uppercase {
  text-transform: uppercase;
}

p {
  color: #4A5158;
  font-family: Roboto;
  font-size: 16px;
  line-height: 24px;
}

.no-margin {
  margin: 0!important;
}

.margin-auto {
  margin: auto!important;
}

.text-bold-medium {
  font-weight: 500;
}

.base-padding {
  padding: 5px;
}
