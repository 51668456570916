.CNG-table {
  &--removeHeaderHeight {
    thead {
      tr {
        height: 26px;
        td,th:first-child {
          height: 0 !important;
          border-spacing: 0px;
        }
      }
    }
  }
  &--withActions {
    thead {
      tr {
        th:last-child {
          width: 100px !important;
        }
      }
    }
  }

  tbody > tr > td {
    background-color: unset !important;
  }
}

.CNG-th {
    text-transform: uppercase;
    color: #929DA8;
}

.CNG-table-row-column:first-child {
  padding-left: 33px !important;
}

.sorting-icon {
    height: 8px;
    width: 6px;
    margin-left: 8px;
}

tr.editable-row td{
  border-top: 1px solid #BDC6CF;
  border-bottom: 1px solid #BDC6CF;

  &:first-child{
    border-left: 1px solid #BDC6CF;
    border-radius: 4px 0 0 4px;
  }

  &:last-child{
    border-right: 1px solid #BDC6CF;
    border-radius: 0 4px 4px 0;
  }
}
