.chill-button {
  width: 117px;
  border-radius: 22px;
  background-color: #0c5371;
  border: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  padding: 2px 7px 2px;
  transition: 300ms background-color ease-in-out;

  &:hover{
    background-color: #10759F;
  }

  &:active{
    background-color: #073143;
  }
}
.chill-button--small {
  height: 24px;
}
.chill-button--medium {
  height: 32px;
}
.chill-button--large {
  height: 40px;
}
.chill-button--secondary {
  background-color: #1D9ABB;

  &:focus {
    background-color: #22B6DD;
  }

  &:hover{
    background-color: #22B6DD;
  }

  &:active{
    background-color: #136379;
  }

  &.chill-button--disabled {
    background-color: #b3dce6;
    cursor: not-allowed!important;
    pointer-events: none;
  }
}

.chill-button--disabled {
  // background-color: #aec7cf;
  opacity: 0.38;
  cursor: not-allowed!important;
  pointer-events: none;
}
.chill-button--inline {
  margin-bottom: 7px;
  align-self: flex-end;
  position: relative;
  z-index: 100;
}
.chill-button--anchor-button {
  background-color: transparent !important;
  border: none !important;
  text-decoration: none !important;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #0c5271;
  font-size: 14px;
  width: auto;
}
