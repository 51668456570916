.version--2{
  /* Variables */
  $primaryLight: #0C5371;
  $primaryMain: #073143;
  $secondaryMain : #136379;
  $secondaryLight : #1d9abb;
  $tabHover: #397E8D !important;

  /* Hover and Active styles for MUITheme */

  .link-style{
    &:hover {
      color: $primaryLight !important;
      text-decoration: underline;
    }
  }

  .secondary-link-style{
    color: $secondaryLight;
    &:hover {
      color: $secondaryLight;
      text-decoration: underline;
    }
  }

  .secondary {
    button {
      &:active:not([disabled]){
        background-color: $secondaryMain !important;

        div > div {
          background-color: $secondaryMain !important;
        }
      }
    }
  }

  .primary {
    button {
      &:active:not([disabled]){
        background-color: $primaryMain !important;

        div > div {
          background-color: $primaryMain !important;
        }
      }
    }
  }

  hr{
    border-bottom-style: solid !important;
  }

  hr.no-border {
    border-bottom-style: unset !important;
  }

  .tabs > div:nth-child(1) button{
    &:hover {
      color: $tabHover;
    }
  }
}

@media only screen and (min-width: 1200px) {
  #quote-sidebar{
    max-width: 21.3% !important;
  }
}

