.row {
  margin: 0;
}

.header-separator {
  box-sizing: border-box;
  height: 2px;
  width: 1052px;
  border-bottom: 1px solid #BDBEC1;
  margin-top: 24px;
  margin-bottom: 39px;
}

.w-100 {
  width: 100% !important;
}

[role="menuitem"][style*="color: rgba(0, 0, 0, 0.88)"]:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 0;
  width: 14px;
  height: 100%;
  background: url(/assets/imgs/icons/check-blue2.svg) no-repeat center center;
  background-size: contain;
}

input[type=checkbox] + div {
  svg:first-child {
    color: #BDC6CF !important;
    fill: #BDC6CF !important;
  }
  label {
    color: #747D86 !important;
  }

}

.mui-text-field {
  label, input::placeholder {
    color: #747D86 !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #4A5158;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.content-loader-wrapper {
  position: relative;
}


.loader-enter {
  opacity: 0.01;
}

.loader-enter.loader-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.loader-leave {
  opacity: 1;
}

.loader-leave.loader-leave-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in-out;
}

button:disabled {
  opacity: 0.3;
  &:hover {
    opacity: 0.3;
  }
}

.disabled {
  opacity: 0.3;
  &:hover {
    opacity: 0.3;
  }
}

.loader-appear{
  opacity: 0.2;
}

.loader-appear.loader-appear-active{
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.staging-flag {
  position: fixed;
  background: red;
  padding: 5px 10px;
  color: white;
  z-index: 110;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 30px;
  font-weight: bold;
  right: -80px;
  width: 250px;
  text-align: center;
  border: 1px solid #f27321;
  margin: 5px 10px;
  outline: 4px solid red;

  &.left {
    left: -80px;
    right: none;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.general-chat {
  transition: all 5000ms ease-in-out;
}

.chat-messages-section {
  transition: all 1000ms ease-in-out;
}

.inactive-art {
  color: red;

  &:hover{
    color: red;
  }
}
