.order-item-products-cart{
  .product-list{
    height: calc(100% - 103px);
    overflow: auto;
    margin: 0 -24px 0 -12px;
    width: 100%;
    padding: 0;
    min-width: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: stretch;

    .product-item{
      display: block;
      width: calc(.10 * (100vw - 24px));
      min-width: 238px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #BDC6CF;
      margin-right: 17px;
      margin-bottom: 17px;

      &.selected{
        border: 1px solid #10759F;
        background-color: rgba(189,198,207,0.2);
      }

      .product-info {
        width: 100%;
        min-height: 92px;
        background: #FAFAFA;
        padding: 12px;
      }

      .product-container{
        width: 100%;

        .product-image{
          padding: 10px;
          display: flex;
          height: 234px;
          width: inherit;
          max-height: 234px;
        }
      }
    }
  }
}

@media (min-width: 1250px) {
  .order-item-products-cart{
    .product-list{
      .product-item{
        display: block;
        width: calc(.10 * (100vw - 24px));
        min-width: 197.6px;
      }
    }
  }
}
