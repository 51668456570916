.note {
  height: 222px;
  width: 241px;
  border-radius: 4px;
  background-color: rgba(189, 198, 207, 0.07);
  border: 1px solid rgba(189, 198, 207, 0.07);
  padding: 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  display: inline-block;
  position: relative;
  transition: 300ms border linear;
  &:hover {
    cursor: pointer;
  }
}

.note--active {
  border: 1px solid #34b8dd;
}

.note-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
}
.note-add-btn {
  height: 24px;
  width: 24px;
  margin-bottom: 8px;
}
.note-btn-text {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  color: #1D9ABB;
}
.note-new {
  position: relative;
}
.note-new_cancel {
  font-size: 14px;
  font-weight: bold;
  color: #1d9abb;
  line-height: 24px;
}
.note-btn {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #0C5271;
  align-items: center;
  display: flex;
  margin-left: 24px;
  &:hover {
    text-decoration: underline;
  }
  &.disabled {
    opacity: 0.7;
    text-decoration: none!important;
    cursor: not-allowed;
  }
}
.note-btn_icon {
  margin-left: 6px;
}
.note-new_bottom {
  position: absolute;
  bottom: 14px;
  right: 12px;
}
.note-author {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.note_delete {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}
.note_delete_text {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  color: #0c5371;
  margin-right: 6px;
}
.note-text {
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  margin-bottom: 8px;
  color: #747d86;
  white-space: pre-line;
}
.note-text-scroll {
  overflow-y: auto;
  height: 70%;
  word-wrap: break-word;
  hyphens: auto;
}
.note-title {
  font-size: 12px;
  color: #929DA8;
  font-weight: bold;
}
