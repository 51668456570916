.detail-table_col {
  margin-right: 24px;
}

.detail-table_heading {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-weight: bold;
  color: #929da8;
  margin-bottom: 8px;
}

.detail-table_detail {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #929da8;
  margin-bottom: 8px;
}
