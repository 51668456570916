@media print {
  @page {
    size: letter;
    margin: 0;
  }

  #customer-request{
    min-height: 30px;
  }

  .print-icon{
    display: none !important;
    width: 0;
    height: 0;
  }

  body {
    -webkit-print-color-adjust: exact !important;
    display: block !important;
    overflow: visible !important;

    #app {
      display: block !important;
      overflow: visible !important;
    }
  }

  table.report-container {
    page-break-after:always;
  }
  thead.report-header {
    display:table-header-group;
  }
  tfoot.report-footer {
    display:table-footer-group;
  }

  .page-break{
    break-before: always !important;
    page-break-before: always !important;
    page-break-after:always;
  }

  .avoid-break{
    position: relative;
    page-break-inside: avoid;
    margin-bottom: 16px;

    div {
      position: relative;
    }
  }

  .order-print-page{
    margin: 40px;
    visibility: visible !important;
    width: calc(100% - 80px) !important;
    &.height-100{
      height: calc(100% - 80px) !important;
    }
    position: relative;

    .print-header{
      justify-content: space-between;
      margin-bottom: 12px;

      h2 {
        font-size: 30px;
        width: 50%;
        font-weight: 300;
        display: inline-block;
        text-align: left;
        text-transform: capitalize;
      }


      .logo-container{
        text-align: right;
        display: inline-block;
        width: 50%;
        img {
          height: 36px;
        }
      }
    }

    .title {
      font-size: 16px !important;
      width: 50%;
      font-weight: 300;
      display: inline-block;
      text-align: left;
      text-transform: capitalize;
      margin-bottom: 7px;
    }

    .report-container{
      width: 100%;
    }

    .row{
      text-align: left;
      width: 100%;
      justify-content: space-between;
      margin: 8px 0px;
      min-height: 30px;

      &.col-4{
        > div {
          width: 25%;
        }
      }

      &.col-1{
        > div {
          width: 100%;
        }
      }

      > div {
        line-height: 5px;
        width: 50%;

        label {
          font-size: 16px;
          text-transform: uppercase;
          color: rgba(7,49,67,0.4);
          font-weight: bold;
        }

        p {
          font-weight: 300;
          font-size: 16px;
          color: #000000;
          line-height: 34px;
        }
      }
    }

    .content-wrapper{
      padding-bottom: 8px;
    }

    .separator {
      border-bottom: 1px solid rgba(116,125,134,0.5);

    }

    .dash {
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, rgba(151,151,151, 0.5) 50%, rgba(255, 255, 255, 0) 0%);
      background-position: top, right, bottom, left;
      background-repeat: repeat-x, repeat-y;
      background-size: 25px 3px, 1px 10px;
    }

    .gray-color{
      background-color: rgba(216,216,216,0.1);
    }

    .footer{
      position:absolute;
      bottom: 0px;
      padding: 8px;

      .footer-title{
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1.3px;
        line-height: 9px;
        color: #000000;
      }

      .footer-description{
        margin: 0px 4px;
        > div {
          width: 33.3%;
        }
        p, > div {
          display: inline-block;
          margin-bottom:  0px;
          color: #000000;
          padding-bottom:  0px;
          font-size: 16px;
        }

        label {
          opacity: 0.5;
          margin-right: 4px;
        }
      }
    }
  }
}

.order-print-page{
   visibility: hidden;
}

@keyframes print-icon-animation {
    from {font-size: 85px;}
    to {font-size: 70px;}
}

.print-icon{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon-container{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $grey-darker;
    width: 200px;
    height: 200px;
    border-radius: 100px;

    > span {
      animation-name: print-icon-animation;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
}
