.header {
  background-color: $primaryColor;
  height: 72px;
  color: white;
  z-index: 2;
  position: fixed;
  width: 100%;
  width: 100vw;
}
.header_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}

.header_left {
  margin-left: 32px;
}
.header_right {
  margin-right: 32px;
}
// Header Left
.header_left,
.header_left_img {
  width: 37px;
  height: 39px;
}

// Header Center
.header_nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}
.header_nav_link {
  color: white;
  text-decoration: none;
  background-color: #497e95;
  width: 48px;
  height: 48px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  border-radius: 4px;

  &:hover {
    color: white;
    text-decoration: none;
    background-color: #1d9abb;
  }
  &.disabled {
    background-color: #497e95;
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
  }
}
.header_nav_icon {
  width: 24px;
  height: 24px;
  font-size: 24px!important;
}

// Header Right
.header_right {
  position: relative;
}
.header_right_text {
  font-weight: bold;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
}
.header_right_menu {
  position: absolute;
  width: 200px;
  right: 0;
  margin-top: 10px;
  z-index: 10;
}
.header_right_menu_text {
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #747d86 !important;
  &:hover {
    background-color: rgba(73, 126, 149, 0.1) !important;
  }
}

.header_menu_pointer_arrow.active {
  &:before{
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: #08384d;
    z-index: 0;
    left: 50%;
    top: 45px;
    margin-left: -8px;
    transform: scaleX(0.7) rotate(45deg);
    border-top-left-radius: 4px;
  }
}
