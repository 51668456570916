.CNGCard {
    background-color: white;
    border-radius: 4px;
    padding: 32px 32px;
    margin-bottom: 16px;
}

.CNGCard_top {
    display: flex;
    justify-content: flex-start;
    align-items: start;
    margin-bottom: 17px;
}

.CNGCard_icon {
    height: 24px;
    width: 24px;
    margin-right: 8px;
}

.CNGCard_title {
    color: #0C5371;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    margin-right: 8px;
}

.CNGCard_results {
    color: #747D86;
    font-size: 14px;
    line-height: 20px;
}

.CNGCard_divider {
    box-sizing: border-box;
    height: 1px;
    background: #BDBEC1;
    margin-bottom: 15px;
}
