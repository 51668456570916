

.multiple-art-modal{
  height: inherit;
  > div {
    height: inherit;
    box-shadow: none !important;
    > div {
      height: 100%;
      max-height: none !important;
    }
  }

  .art-assignments-modal__assignments{
    height: inherit;
    display: flex;
    justify-content: center;

    .arrow_container{
      width: 5%;
      display: flex;
      justify-content: center;

      &.invert{
        transform: scale(-1);
      }
    }

    .assignments__art{
      width: 550px;
      display: flex;
      justify-content: flex-start;
      margin-top: 15px;
      align-items: center;
      flex-direction: column;

      >span {
        color: #929DA8;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }

      img {
        margin: 0 auto;
        width: 550px;
        height: calc(100% - 50px);
        object-fit: contain;
        border: 1px solid #BDBEC1;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .multiple-art-modal{
    .assignments__art{
      width: 300px !important;

      img {
        width: 300px !important;
      }
    }
  }
}
