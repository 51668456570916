.total-summary{
  width: 70%
}

.summary {
  .billing-info {
    width: 67%;
  }

  .totals{
    width: 95%;
    margin-right: 30px;
    flex-direction: row;
    justify-content: space-around;

    > div {
      width: 100%;
      margin-left: 15px;
    }
  }
}
