.btn-1 {
  height: 24px;
  width: 117px;
  border-radius: 22px;
  background-color: #0c5371;
  border: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
}
.btn-light-blue {
  background-color: #1d9abb;
}
.btn-1--medium {
  width: 137px;
  height: 40px;
}
.btn-1--large {
  width: 151px;
  height: 40px;
}
.btn-1--larger {
  width: 162px;
  height: 40px;
}
.btn-1--dropship {
  width: 198px;
}
.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}
.btn-icon-size {
  margin-right: 6px;
  height: 12px;
  width: 12px;
}
.btn-icon-size--small {
  margin-right: 4px;
  height: 20px;
  width: 20px;
}
.btn-1-disabled, .btn-1[disabled], .btn-1-disabled:hover, .btn-1[disabled]:hover {
  cursor: not-allowed;
  opacity: 0.38;
}

.btn--addCompany {
  margin-top: -20px;
}
.btn--formCardHeader {
  margin-left: 16px;
  text-transform: uppercase;
}
.chill-button--inline {
  margin-bottom: 7px;
  align-self: flex-end;
}
