html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

/**
 * Firefox specific rule
 */

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  margin: 0;
}

body {
  line-height: 1.4em;
  color: $textColor;
  font-family: "Roboto", Arial, Helvetica, sans-serif;

  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  background: #f4f8f9;
  overflow:  hidden;
}

button:hover,
button:active,
button:focus {
  cursor: pointer;
  outline: none;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}
