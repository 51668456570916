.item-list, .colors-list{
  height: calc(100% - 80px);
  overflow: auto;
  margin: 36px -24px 0 -12px;
  width: 100%;
  padding: 0;
  min-width: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: stretch;

  .item{
    width: calc(.10 * (100vw - 24px));
    min-width: 238px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 17px;
    margin-bottom: 17px;

    .item-container {
      width: 100%;
      margin-bottom: 9px;

      .item-image {
        display: flex;
        height: 238px;
        width: inherit;
        max-height: 238px;

        &.selected{
          border: 1px solid #10759F;
          background-color: rgba(189,198,207,0.2);
        }
      }

      .color {
        height: 120px;
        max-height: 120px;
      }
    }
  }

  .color {
    width: calc(.05 * (100vw - 24px));
    min-width: 120px;
  }
}
.item-list-with-filter {
  height: calc(100% - 165px);
}
.colors-list{
  height: calc(100% - 191px);
}

.steps {

  .step {
    height: 300px;
    width: 356px;
    border-radius: 4px;
    margin: 0 12px;
    background-color: #FFFFFF;
  }
  
  .active-step {
    background-color: rgba(90, 204, 236, 0.1);
    border: 1px solid rgba(52, 184, 221, 0.1);
  }
  
}

.quote-item-edit-modal-content {
  overflow: -moz-scrollbars-none;
  overflow-y: auto !important;
  -ms-overflow-style: none;
}
.quote-item-edit-modal-content::-webkit-scrollbar {
  display: none;
}