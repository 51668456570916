.searchInput-container {
    height: 40px;
    width: 280px;
    border: 1px solid #BDC6CF;
    border-radius: 20px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 18px;
    position: relative;
    &--disabled {
        opacity: 0.6;
    }
}
.searchInput_icon {
    position: absolute;
    right: 18px;
    height: 18px;
    width: 18px;
    bottom:10px;
}