.archiveModalStyles > div {
    height: 100%;
    padding: 40px;
    position: relative;
}

.archiveModal_title {
    color: #0C5371;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin: 0;
    font-weight: normal;

}
.archiveModal_title:last-of-type {
    margin-bottom: 20px;
}

.archiveModal_text {
    color: #747D86;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 40px;
}

.archiveModal_btn {
    width: 360px;
    height: 40px;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}
.archiveModal_cancel {
    color: #0C5271;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 24px;
    display: block;
    margin: 0 auto;
    text-align: center;
    cursor: pointer;
}
.archiveModal_closeIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 16px;
    width: 16px;
    &:hover {
        cursor: pointer;
    }
}
