.card {
  background-color: white;
  padding: 40px 32px;
}

.card-message {
  background-color: white;
  padding: 81px 0px;
}

.card-message_text {
  color: #0c5371;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  font-weight: normal;
  height: 32px;
}
