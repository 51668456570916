.site-container {
  min-width: 230px;
  max-width: 1440px;
  margin: 0 auto;
}
.content-container {
  max-width: 1116px;
  margin: 0 auto;
  margin-top: 48px;
}

// Styles for Sticky footer
html,
body,
#app,
#app > div:first-of-type {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  overflow: auto;
}

.content--subheader {
  margin-top: 60px;
}

@media print {
  body {
    overflow: hidden;
    font-size: 9.5pt;
    zoom: 65%;
    padding: 30px  20px !important;
  }

  .billing-report-title {
    padding-top: 50px;
    background: #fff !important;
    color: #0c5371 !important;
    font-size: 36px !important;
    text-align: center;
  }

  body #app .PageContainer {
    padding-top: 0px !important;
    background: #fff !important;
    font-size: 12px !important;


    * {
      text-overflow: unset !important;
      page-break-inside: avoid;
    }

      overflow-y: hidden !important;


    .BodyContainer{
      background: #fff !important;
      .order-item-card {
        div{
        font-size: 16px !important;
      }

        button {display: none !important;}
      }

      .dsod-table {
        td, .subtext {
          font-size: 10px !important;
          height: 8px !important;
        }

        td:nth-child(2){
          width: 260px !important;
        }
      }

      .ContentContainer {
        margin-top: 0px !important;
        padding-top: 10px !important;

        .order-info-row {
          flex-wrap: wrap;

          #cng-ref-number {
            width: 100% !important;

            label { display: none !important; }
            > p {
              width: 100% !important;
              color: rgb(12, 83, 113) !important;
              font-size: 30px !important;
              font-weight: 300;
              margin-bottom: 20px;
            }

          }
        }

        .company_details_section, .order-note-container {
          span, div {
            font-size: 16px !important;
          }
        }

        .order-item-row{
          font-size: 10px !important;
          height: 23px;

          div:nth-child(4){
            width: 120px !important;
          }

          div:nth-child(5){
            width: 150px !important;
          }

          div:nth-child(6){
            width: 120px !important;
          }

          div:nth-child(7){
            width: 144 !important;
          }
        }
      }
    }

    #sub-header {
      width: 100% !important;
      background: #fff !important;
      color: #0c5371 !important;
      font-size: 36px !important;
    }
  }

  @page {
    size: Letter;
  }
}
