.app-body {
  flex: 1 0 auto;
  margin-bottom: 16px;
  background: #f4f8f9;
}

.page-container {
  background: #f4f8f9;
  height: 100%;
  display: flex;
  flex-direction: column;
}
