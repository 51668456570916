.reorderpage {
  margin-top: 20px;
}

.reorderpage_top {
  margin-bottom: 24px;
}
.reorderpage_top_icon {
  height: 26px;
  width: 26px;
  margin-right: 8px;
}

.order-item-card {
  > button {
    padding: 0px;
    right: -15px !important;
  }
}

.new-order--section{
  background-color: rgba(255,255,255,0.5);
  margin-bottom: 16px;
  height: 64px;

  .new-order--add-link{
    color: $blue-light !important;

    span {
      margin-right: 10px;
    }
  }
}
