.company-card {
  background: white;
  padding: 32px;
}

.company_title {
  height: 41px;
  color: #0c5371;
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  margin-bottom: 16px;
}

.company_top_info {
  display: flex;
  margin-bottom: 17px;
}
.company_top_info_section {
  margin-right: 32px;
}
.company_top_customer-message {
  width: 100%;
  display: flex;
  flex-direction: column;
}

// Top Detail Styles
.company_bot_right {
  min-width: 0;
}

.company_bot_info {
  display: flex;
  margin-bottom: 32px;
}
.company_bot_brands {
  margin-bottom: 0.75em;
}
.company_bot_left {
  border-right: 1px solid #bdc6cf;
  margin-right: 24px;
}
.company_details {
  display: flex;
  justify-content: space-between;
  width: 703px;
}

.company_details_section {
  width: 80%;
}
.company_details_detail {
  display: flex;
  justify-content: space-between;
}

.detail-heading {
  height: 20px;
  width: 68px;
  color: #747d86;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  display: inline-block;
  margin-right: 31px;
  margin-bottom: 8px;
}
.detail_addressBlock {
  display: inline-block;
  vertical-align: text-top;
}

// Tab styles
.tab-container {
  padding-top: 17px;
}
.tab_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

//
.company_field_delete {
  margin-top: -2px;
  margin-left: -22px;
  height: 15px;
  width: 12.5px;
  &:hover {
    cursor: pointer;
  }
}
.company_field {
  height: 24px;
  display: flex;
  margin-bottom: 40px;
}

.company-tabs {
  .tab-container__pagination {
    min-height: 640px;
  }
}

.company-select-menu-item, .brand-select-menu-item {
  div {
    div {
      div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}