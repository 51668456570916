.usercreate_row {
  display: flex;
}
.usercreate_row_bot {
  margin-bottom: 58px;
}

.trash-icon {
  height: 20px;
  width: 20px;
  vertical-align: top;
  margin-top: -7px;
  margin-left: -20px;
  &:hover {
    cursor: pointer;
  }
}
